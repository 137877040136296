/* For the checked (Product) state */
.ui.toggle.checkbox.green-toggle input:checked ~ .box:before,
.ui.toggle.checkbox.green-toggle input:checked ~ label:before {
  background-color: #21ba45 !important; /* Semantic UI green */
}

/* For the unchecked (Supply) state */
.ui.toggle.checkbox.red-toggle input:not(:checked) ~ .box:before,
.ui.toggle.checkbox.red-toggle input:not(:checked) ~ label:before {
  background-color: #db2828 !important; /* Semantic UI red */
}
