.item.no-pointer {
  cursor: default !important;
}

.nested-modal {
  z-index: 9999 !important;
}

.family-label-hovered {
  background-color: white !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
  transition: box-shadow 0.2s ease;
}

.highlight-once {
  animation: highlight-once 1s ease-in-out infinite;
  background-color: white;
}

@keyframes highlight-once {
  0% {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: #eceaea;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}
