.ui.attached.tabular.item {
  position: relative;
}
.ui.attached.tabular.menu .active.item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  background-color: white; /* Match your background color */
  z-index: 1;
}
